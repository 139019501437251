import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import Layout from '../components/Layout'
import SEO from '../components/seo'
import AboutUsCom from '../components/AboutUsCom'

const AboutUs = () => {
  const intl = useIntl()
  return (
    <Layout>
      <SEO
        dir={intl.locale === 'en' ? 'ltr' : 'rtl'}
        title={intl.formatMessage({ id: 'about_us' })}
        description={intl.formatMessage({ id: 'about_us_12' })}
      />
      <AboutUsCom />
    </Layout>
  )
}

export default AboutUs
