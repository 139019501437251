import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { Border, Container, Company, Companies, Href } from './aboutUs.style'
import { H3, ParagraphMedium, Subtitle } from '../typography'
import Cavgna from '../../images/cavagna_group.png'
import AyGaz from '../../images/aya_gaz.png'
import Rego from '../../images/rego.svg'

const AboutUsCom = () => {
  const intl = useIntl()
  return (
    <Container>
      <H3 style={{ marginBottom: '1rem' }}>
        {intl.formatMessage({ id: 'about_us_1' })}
      </H3>
      <ParagraphMedium>
        {intl.formatMessage({ id: 'about_us_12' })}
      </ParagraphMedium>
      <br />
      <br />
      <br />
      <H3 style={{ marginBottom: '1rem' }}>
        {intl.formatMessage({ id: 'about_us_2' })}
      </H3>
      <ParagraphMedium>
        {intl.formatMessage({ id: 'about_us_21' })}
      </ParagraphMedium>
      <br />
      <br />
      <br />
      <H3 style={{ marginBottom: '1rem' }}>
        {intl.formatMessage({ id: 'about_us_3' })}
      </H3>
      <Companies>
        <Href href="https://www.cavagnagroup.com">
          <Company>
            <Border />
            <img src={Cavgna} alt="Cavagna" width="65px" />
            <Subtitle style={{ textAlign: 'center' }}>Cavgna Group</Subtitle>
          </Company>
        </Href>
        <Href href="http://www.regoproducts.com/">
          <Company>
            <Border />
            <img src={Rego} alt="Rego" width="65px" />
            <Subtitle style={{ textAlign: 'center' }}>Rego LPG</Subtitle>
          </Company>
        </Href>
        <Href href="https://www.aygaz.com.tr/">
          <Company>
            <Border />
            <img src={AyGaz} alt="AyaGaz" width="65px" />
            <Subtitle style={{ textAlign: 'center' }}>Aya Gaz</Subtitle>
          </Company>
        </Href>
      </Companies>
    </Container>
  )
}

export default AboutUsCom
