import styled from 'styled-components'
import { colors } from '../../utils'

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;

  flex-direction: column;
  margin: 5rem 13rem;
  @media (max-width: 800px) {
    width: 90%;
    margin: 3rem auto;
  }
`
export const Companies = styled.div`
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  margin-top: 4rem;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`

export const Href = styled.a`
  text-decoration: none;

  color: ${colors.primary};
  padding: 1rem 0rem;
`
export const Border = styled.div`
  position: absolute;
  width: 104%;
  height: 120px;
  border-left: 4px solid ${colors.lightGray};
  border-right: 4px solid ${colors.lightGray};
  transition: border-color 2s;

  &:hover {
    border-left: 4px solid ${colors.primary};
    border-right: 4px solid ${colors.primary};
  }

  @media (max-width: 800px) {
    width: 102%;
    height: 98px;
  }
`
export const Company = styled.div`
  display: flex;
  position: relative;
  margin: 2rem;
  flex-direction: column;
  border-radius: 3px;
  align-items: center;
  padding: 2rem 4rem;
  border: 2px solid ${colors.gray2};
`
